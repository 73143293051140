<!--舆情报告页面-->
<template>
  <section class="report-wrap">
    <section class="report-content" @click="hideBatchPanel()">
      <div id="div1">
        <div >
          <div class="items">
            <label class="title" style="position: relative;">发布时间：</label>
            <el-radio-group :disabled="loading" v-model="form.pubDate" size="mini" @change="changeHandler">
              <el-radio label="6" border>全部</el-radio>
              <el-radio label="1" border>今天</el-radio>
              <el-radio label="2" border>最近三天</el-radio>
              <el-radio label="3" border>最近七天</el-radio>
              <el-radio label="4" border>最近三十天</el-radio>
             <el-radio label="5" border @click.native="pubDateStyle.display = 'inline-block'">自定义</el-radio>
             <el-date-picker v-model="form.pubDateFrame" type="datetimerange" size="mini" range-separator="--" unlink-panels
               start-placeholder="开始日期" end-placeholder="结束日期" :style="pubDateStyle" @blur="pubDateStyle.display = 'none'">
             </el-date-picker>
            </el-radio-group>
          </div>
          <div class="items">
            <label id="isMerge" class="title">数据合并：</label>
            <el-radio-group :disabled="loading" v-model="form.isMerge" size="mini" @change="isMergeChange">
              <el-radio  label="1" border>合并</el-radio>
              <el-radio  label="0" border>不合并</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div v-if="openFilter">
          <div class="items">
            <label class="title">情感类型：</label>
            <el-radio-group :disabled="loading" id="emotion" v-model="form.emotionType" size="mini">
              <el-radio label="-1" border style="margin-right: 1px">全部</el-radio>
              <el-radio label="0" border style="margin-right: 1px">正面</el-radio>
              <el-radio label="1" border style="margin-right: 1px">负面</el-radio>
              <el-radio label="2" border>敏感</el-radio>
            </el-radio-group>
          </div>


          <div class="items">
            <label id="sortBy" class="title">信息排列：</label>
            <el-radio-group v-model="form.order" size="mini" @change="selectChange" :disabled="form.isMerge != '1' || loading">
              <el-radio  label="1" border>时间</el-radio>
              <el-radio  label="2" border>相似数</el-radio>
            </el-radio-group>
            <el-radio-group style="margin-left: 10px" v-model="form.order" size="mini" :disabled="form.isMerge === '1' || loading" @change="selectChange">
              <el-radio border label="1">正序</el-radio>
              <el-radio border label="2">倒序</el-radio>
            </el-radio-group>
          </div>


        </div>
        <div v-loading="loading" id="medium" class="sourceType" v-if="openFilter">
          <div class="allData">
            <label class="title">来源：</label>
            <el-checkbox :disabled="loading" v-model="checkAll" @change="handleSelectAllChange">{{total}}</el-checkbox>
          </div>
          <div class="mediumSource">
            <label class="title">媒体：</label>
            <el-checkbox-group :disabled="loading" v-model="form.sourceType" @change="handelTypeChange">
              <el-checkbox v-for="item in siteTypeList" v-if="item.id==1||item.id == 6||item.id==8||item.id == 10||item.id==11" :label="item.id" :name="item.siteName" :key="item.id">{{ item.siteName }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="community">
            <label class="title">社区：</label>
            <el-checkbox-group :disabled="loading" v-model="form.sourceType" @change="handelTypeChange">
              <el-checkbox v-for="item in siteTypeList" v-if="item.id==2||item.id == 4||item.id==5||item.id == 9" :label="item.id" :name="item.siteName" :key="item.id">{{ item.siteName }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="micro">
            <label class="title">双微：</label>
            <el-checkbox-group :disabled="loading" v-model="form.sourceType" @change="handelTypeChange">
              <el-checkbox v-for="item in siteTypeList" v-if="item.id==3||item.id == 7" :label="item.id" :name="item.siteName" :key="item.id">{{ item.siteName }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div v-if="openFilter">
          <div class="items">
            <label class="title">关键词：</label>
            <el-input style="font-size: 14px" :disabled="loading" size="mini" placeholder="请输入关键词"  v-model="form.keyWord">
            </el-input>
            <el-radio-group v-model="form.scope" size="mini" :disabled="form.keyWord === '' || loading">
              <el-radio label="1" border>标题</el-radio>
              <el-radio label="2" border>标题+正文</el-radio>
            </el-radio-group>
          </div>


          <div class="items">
            <label class="title">标签查询：</label>
             <el-select :disabled="loading" size="mini" v-model="form.markIdList" multiple filterable placeholder="请选择标签">
                <el-option-group v-for="group in markgrouOne" :key="group.mgroupName" :label="group.mgroupName">
                  <el-option v-for="item in group.markList" :key="item.id" :label="item.markName" :value="item.id">
                  </el-option>
                </el-option-group>
              </el-select>
          </div>


        </div>
        <div v-if="openFilter">
          <div class="items">
            <label class="title">发布者：</label>
            <el-input style="font-size: 14px" :disabled="loading" size="mini" placeholder="请输入发布者"  v-model="form.author">
            </el-input>
          </div>


          <div class="items">
            <label class="title">来源站点：</label>
            <el-input  style="font-size: 14px" :disabled="loading" size="mini" placeholder="请输入来源站点"  v-model="form.siteName">
            </el-input>
          </div>


        </div>

          <!-- <el-form-item> -->
            <!-- <label class="el-form-item__label" style="width: 172px;height: 40px;margin-left: -187px;"></label> -->
          <div class="btn-wrapper" style="text-align: center;font-size: 14px;">
            <el-button type="primary" id="searchBtn" size="mini" @click="search" :disabled="loading">查询</el-button>
            <el-button @click="reset" size="mini" :disabled="loading">重置</el-button>
            <el-button @click.native="showExport=true;fileName=''" size="mini" :disabled="loading">导出源数据</el-button>
            <el-dropdown style="margin-left: 10px; margin-right: 10px" @command="createReportByTem">
              <el-button type="success" size="mini" :disabled="loading">
                引用模板生成报告<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in sysTemArray" :key="item.id" :command="item.id">{{ item.templateName }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" size="mini" @click="showCheckFlag = true;form.name=''" :disabled="loading">保存筛选条件</el-button>
            <el-select size="mini" style="margin-left: 10px" v-model="checkIndex" filterable placeholder="保存条件" @change="GetChecked">
              <el-option v-for="(item,index) in submitArr" :key="index" :label="item.name " :value="index">
                <span style="float: left">{{ item.name }}</span>
                <i style="float: right;margin: 10px 0;cursor: pointer;" class="el-icon-close" @click.stop="removeCondition(index)"></i>
              </el-option>
            </el-select>
            <span class="el-dropdown-link" style="float: right" @click="dropUp" v-if="openFilter">
             <img style="width: 40px; height: 40px" src="@/assets/images/icon-dropup-new.png" >
            </span>
            <span class="el-dropdown-link" style="float: right" @click="dropUp" v-else>
             <img style="width: 40px; height: 40px" src="@/assets/images/icon-dropdown-new.png" >
            </span>
          </div>
      </div>
      <div id="div2">
        <div id="div3">
          <transition name="el-zoom-in-top">
            <el-button class="batchBtn" plain size="mini" @click.stop="showBatchPanel" @mouseleave="hideBatchPanel()">批量操作
              <div class="batchMenu" v-if="showBatchPanelFlag">
                <ul class="menuUl">
                  <li @click="showEmotionPanel">移动至<i class="el-icon-caret-right"
                      style="padding-left: 20px"></i>
                    <div class="emotionMenu" v-if="showEmotionPanelFlag">
                      <ul class="emotionUl">
                        <li v-for="item in favorFolderList" @click="ismerge? moveFolder(item.id) :showMoveFlag(item.id)">{{item.folderName}}</li>
                      </ul>
                    </div>
                  </li>
                  <li @click.stop="ismerge? batchDelectFavors() : selectBatchDeleteType()">删除</li>
                </ul>
              </div>
            </el-button>
          </transition>
<!--          <el-button plain size="mini" @click="createDaily">生成报告</el-button>-->
<!--          <el-button plain size="mini" @click="setTemplate">模板设置</el-button>-->
        </div>
        <div id="div5">
          <el-table v-loading="loading" element-loading-text="正在查询" id="table" ref="multipleTable"
                    :data="tableData" border tooltip-effect="dark"
                    style="width: calc(100% - 40px);margin-bottom: 80px;"
                    @selection-change="handleSelectionChange">
            <el-table-column  :resizable="false" type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column  :resizable="false" :label="viewType == 1 ? '标题' : '标题+摘要'" prop="title" header-align="center" min-width="200">
              <template scope="{ row }">
                <!-- <div class="header">
                  <div class="operator-wrapper">
                    <el-tag size="mini" effect="dark" type="info" v-show="showItems" v-if="row.isIntelligence > 0" style="margin-right: 3px">情报</el-tag>
                    <el-tag size="mini" effect="dark" type="danger" v-if="row.isWarning === 1">预警</el-tag>
                  </div>
                </div> -->
                <div class="card-content">
                  <div class="iconList" style="float: right;">
                    <!-- <el-tooltip class="item" effect="dark" content="移动至收藏夹" placement="bottom">
                      <el-dropdown @command="handleCommand" trigger="click">
                        <i class="iconfont icon-shoucang"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-for="item in favorFolderList" :command="{'id':item.id,'row':row}">{{item.folderName}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-tooltip> -->
                    <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                      <i class="iconfont icon-shanchu" @click="ismerge ? delectFavors(row) : selectDeleteType(row)"></i>
                    </el-tooltip>
                  </div>
                  <div style="display: inline-block;vertical-align: top;">
                    <el-tag size="mini" v-if="row.isDanger === 0" effect="dark" style="background-color: #84BFEC;border-color:#84BFEC;height:19px;padding:0 3px;border-radius:2px;">正</el-tag>
                    <el-tag size="mini" v-else-if="row.isDanger === 1"  effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;">负</el-tag>
                    <el-tag size="mini" v-else-if="row.isDanger === 2" effect="dark" style="background-color: #EDD990;border-color:#EDD990;height:19px;padding:0 3px;border-radius:2px;">敏</el-tag>
                    <el-tag size="mini" effect="dark" style="background-color: #90A7ED;border-color:#90A7ED;height:19px;padding:0 3px;border-radius:2px;" class="isOriginal" v-if="row.isOriginal === 0">转</el-tag>
                    <el-tag size="mini" effect="dark" style="background-color: #EDBF90;border-color:#EDBF90;height:19px;padding:0 3px;border-radius:2px;" class="isOriginal" v-else-if="row.isOriginal === 1 ">原</el-tag>
                    <el-tag size="mini" effect="dark" v-if="showItems&&row.isIntelligence > 0" style="background-color: #5A9EFC;border-color:#5A9EFC;height:19px;padding:0 3px;border-radius:2px;">情</el-tag>
                    <el-tag size="mini" effect="dark" v-if="row.isWarning === 1" style="background-color: #FC5A5A;border-color:#FC5A5A;height:19px;padding:0 3px;border-radius:2px;">警</el-tag>
                  </div>
                  <div class="title" v-html="row.title" @click="getNewsDetailsThroughSn(row.sn)"></div>
                  <div class="abstract" v-html="row.summary"></div>

                </div>
                <div class="footer">
                  <div class="toSource" style="float: right;color: #3472c7;">
                    <el-link type="info" :href="row.url"  target="_blank">查看原文 &gt; </el-link>
                  </div>
                  <span v-if="row.authorName !== null && row.authorName !== ''">
                    发布者：{{row.authorName}}
                  </span>
                  <span v-if="row.markList.length > 0">
                    标签：<span class="tags" v-for="(tag, index) in row.markList" :key="index">{{tag}}</span>
                  </span>

                </div>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" prop="likeCount" label="新闻组" align="center" width="70" show-overflow-tooltip>
              <template scope="{ row }">
                <div v-if="row.childCount > 0"><el-link :underline="false" type="primary" @click="showChildNews(row.groupSN, row.sn)">{{row.childCount}}</el-link></div>
                <div v-if="row.childCount === 0">{{row.childCount}}</div>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" label="来源" align="center"  width="100">
              <template scope="{ row }">
               <span>
                  {{ row.sourceName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" prop="location" label="位置" align="center" width="80">
            </el-table-column>
            <el-table-column  :resizable="false" prop="publishDate" label="时间" align="center" width="100" sortable>
              <template scope="{ row }">
                {{formatDate(row.publishDate)}}
              </template>
            </el-table-column>
            <!-- <el-table-column  :resizable="false" label="操作" align="center" width="60">
              <template scope="{ row, $index }">
                <el-tooltip class="item" effect="dark" content="移动至收藏夹" placement="bottom">
                  <el-dropdown @command="handleCommand" trigger="click" style="cursor:pointer">
                    <i class="iconfont icon-shoucang"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in favorFolderList" :command="{'id':item.id,'row':row}">{{item.folderName}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                  <!-- <i class="iconfont icon-shoucang" @mouseleave="handleLeave(row)" @click="handleOver(row)">
                    <div class="showOneEmotionPanel" v-show="row.dropdownShow">
                      <ul class="oneEmotionUl">
                        <li v-for="item in favorFolderList" @click="ismerge? moveFolder(item.id,row) : showMoveFlag(item.id,row)">{{item.folderName}}</li>
                      </ul>
                    </div>
                  </i> -->
                <!-- </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                  <i class="iconfont icon-shanchu" style="cursor: pointer;" @click="ismerge ? delectFavors(row) : selectDeleteType(row)"></i>
                </el-tooltip> -->

                <!-- <i :class="['el-icon-more', 'ellipsis', tableData.length - $index <= 4 ? 'bottomEllipsis' : '']" style="width: 100%;height: 100%;"
                   @mouseleave="handleLeave(row)" @click="handleOver(row)">
                  <div class="oneOperationPanel" v-show="row.dropdownShow">
                    <ul class="oneOperationUl">
                      <li @click="showMoveFolderPanel()">移动至<i class="el-icon-caret-right" style="padding-left: 32px"></i>
                        <div class="showOneEmotionPanel" v-if="showMovePanel">
                          <ul class="oneEmotionUl">
                            <li v-for="item in favorFolderList" @click="ismerge? moveFolder(item.id,row) : showMoveFlag(item.id,row)">{{item.folderName}}</li>
                          </ul>
                        </div>
                      </li>
                      <li @click="ismerge ? delectFavors(row) : selectDeleteType(row)">删除</li>
                    </ul>
                  </div>
                </i> -->
              <!-- </template>
            </el-table-column> -->
          </el-table>
          <section class="list-bottom">
            <span class="count" v-if="form.isMerge === '1'">共{{count}}组记录</span>
            <span class="count" v-else>共{{count}}条记录</span>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
              :page-sizes="[10, 20, 30, 50]" :page-size="limit" layout="sizes, prev, pager, next, jumper"
              :total="count">
            </el-pagination>
          </section>
        </div>
      </div>
    </section>
    <el-dialog title="导出" width="40%" :visible.sync="showExport">
      <el-form>
        <el-form-item label="文件名称" label-width="120px">
          <el-input v-model="fileName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExport=false">取 消</el-button>
        <el-button type="primary" @click="viewsAllExportExcel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="保存条件" width="30%" top="25vh" :visible.sync="showCheckFlag">
      <el-form>
        <el-form-item label="条件名称：" class="sort" prop="sorts">
          <el-input v-model="form.name" autocomplete="off" style="width: 220px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="showCheckFlag = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showFavorFlag" width="30%" center>
      <el-checkbox-group v-model="deleteFavorType" @change="deleteTypeChange">
        <el-checkbox label="0">只删除主新闻？</el-checkbox>
        <el-checkbox label="1">同时删除主新闻和子新闻？</el-checkbox>
      </el-checkbox-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showFavorFlag = false">取 消</el-button>
        <el-button type="primary" @click="delectFavors(rowList)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showBatchDeleteFlag" width="30%" center>
      <el-checkbox-group v-model="batchDeleteType" @change="batchDeleteChange">
        <el-checkbox label="0">只删除主新闻？</el-checkbox>
        <el-checkbox label="1">同时删除主新闻和子新闻？</el-checkbox>
      </el-checkbox-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showBatchDeleteFlag = false">取 消</el-button>
        <el-button type="primary" @click="batchDelectFavors()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showFavorMoveFlag" width="30%" center>
      <el-checkbox-group v-model="moveFavorType" @change="moveTypeChange">
        <el-checkbox label="0">只移动主新闻？</el-checkbox>
        <el-checkbox label="1">同时移动主新闻和子新闻？</el-checkbox>
      </el-checkbox-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showFavorMoveFlag = false">取 消</el-button>
        <el-button type="primary" @click="moveFavorNews(newFolder,favorNewsList)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showRemoveChildNews" width="30%" center>
      <span class="prompt"><i class="el-icon-warning"></i>确认从收藏夹中删除新闻？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelRemoveGroup">取 消</el-button>
        <el-button type="primary" @click="confRemoveGroup">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新闻组" :visible.sync="showChildNewsFlag" width="80%">
      <el-table :data="childNewsTable" border v-loading="groupLoading" max-height="600">
        <el-table-column  :resizable="false" property="title" label="标题" header-align="center" >
          <template scope="{ row }">
              <div>
                <div class="card-content">
                  <div style="display: inline-block;vertical-align: top;margin-right: 5px;">
                    <el-tag size="mini" effect="dark" :type="row.isDanger === 0 ? 'defult' : row.isDanger === 1 ? 'danger' : 'warning'">{{ row.isDanger === 0 ? '正面' : row.isDanger === 1 ? '负面' : '敏感' }}</el-tag>
                  </div>
                  <i class="el-icon-delete" style="float: right;cursor: pointer;font-size: 16px;color:#a8afb0" @click="deleteChildNews(row)"></i>
                  <div class="title" v-html="row.title" @click="getNewsDetailsThroughSn(row.sn)" @mouseup="rightClick($event)"></div>
                  <div v-html="row.summary" @mouseup="rightClick($event)"></div>
                </div>
                <div class="footer">
                  <span v-if="row.authorName !== null && row.authorName !== ''">
                    发布者：{{row.authorName}}
                  </span>
                  <span v-if="row.publishdateStd !== null && row.publishdateStd !== ''">
                    发布时间：{{row.publishdateStd}}
                  </span>
                  <span v-if="row.customeSource === null || row.customeSource === ''">来源：{{ row.sourceName }}</span>
                  <span v-else>来源：{{ row.customeSource }}</span>
                  <span v-if="row.markList!==null && row.markList.length > 0">
                    标签：<el-tag size="mini" effect="plain" v-for="(tag, index) in row.markList" :key="index">{{tag}}</el-tag>
                  </span>
                </div>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <el-pagination @size-change="handleChildSizeChange" @current-change="handleChildCurrentChange" :current-page="child.currentPage"
                       :page-sizes="[10, 20, 30, 50]" :page-size="child.size" layout="total, sizes, prev, pager, next, jumper" :total="child.count">
        </el-pagination>
      </section>
    </el-dialog>
    <el-dialog title="模板设置" :visible.sync="showTemplateDialog" top="3%" width="80%" center>
      <dailyTemplate ref="template" @visible="getVisible"></dailyTemplate>
    </el-dialog>
    <el-dialog title="生成日报" :visible.sync="showReportDialog" top="8%" width="80%" center>
      <dailyData @visible="getVisible"></dailyData>
    </el-dialog>
  </section>
</template>
<script>
import dailyTemplate from './dailyTemplate'
import dailyData from './dailyData'
import { sourceCountWithFolder, showData, listAllSiteType, batchDeleteNews, batchUpdateSummary, batchUpdateEmotion, setSubject, addNewsGroup, listSonData, delSonNewsForSN, listAllNewsGroup, exportWord, showLikeSonData, cancelCreationOfDailyReportBySn, updataMainNews, deleteAndNotIncludeSon } from '@/api/detection'
import { setNewsIsRep, addIntelligence, PushNews, listMarkGroupByCid, saveMarkRelationship, saveMark, removeAllMark, selectAllType, selectnNodeBySn } from '@/api/dataOutput'
import { getFavorListData,removeFavorites,getFavorList,batchRemove,updateFavorites,exportExcel } from '@/api/favor'
import { getToken } from '@/utils/auth'
import event from '@/utils/eventHub.js'
import { apiBaseURL } from '@/utils/request'
import $ from 'jquery'
import {selectTemplate} from "@/api/daily/template";
import { listTemplates, createWordByFf } from '@/api/diy'


const cityOptions = ['上海', '北京', '广州', '深圳', '澳门', '香港', '宁波', '邯郸']
export default {
  created () {
    setTimeout(() => {
      var tableId = document.getElementsByClassName('el-table__body')[0]
      for (var i = 0; i < tableId.rows.length; i++) {
        this.dataHtml.push(tableId.rows[i].cells[2].innerHTML)
      }
    }, 1000)
    if (localStorage.getItem(`reportConditions_${this.$store.state.user.name}`)) {
      this.submitArr = JSON.parse(localStorage.getItem(`reportConditions_${this.$store.state.user.name}`))
      if (this.submitArr.length > 0) {
        var len = this.submitArr.length - 1
        this.form = JSON.parse(JSON.stringify(this.submitArr[len]))
        this.checkIndex = this.submitArr.length - 1
      }
    }
  },
  mounted () {
    if (this.form.isMerge === '1') {
      this.isdisplay = true
      this.ismerge = false
    } else {
      this.isdisplay = false
      this.ismerge = true
    }
    // this.getFavorList()
    this.listSiteType()
    event.$on('favorId', val => {
      this.form.folderId = val
    })
    event.$on('favorList', val => {
      this.favorFolderList = val
    })
    // this.viewShowData()
    this.loadDiyTem()
    this.viewlistMarkGroupByCid()
    this.listAllNode()
  },
  methods: {
    GetChecked(){
      this.form = this.submitArr[this.checkIndex]
    },
    handleCommand(command) {
      if(this.ismerge){
        this.moveFolder(command.id,command.row)
      }else{
        this.showMoveFlag(command.id,command.row)
      }

    },
    async createDaily () {
      const res = await selectTemplate()
      console.log(res)
      if (res.code === 1) {
        this.$alert('您还未设置模板，请先设置模板', '提示', {
          confirmButtonText: '去设置',
          type: 'warning',
          callback: () => {
            this.showTemplateDialog = true
          }
        })
      } else {
        localStorage.setItem("ffid",this.form.folderId)
        this.showReportDialog = true
      }
      // this.$router.push('dailyData')
    },
    // 模板设置
    setTemplate () {
      this.showTemplateDialog = true
      this.$refs.template.initializeSelect();
    },
    getVisible (data) {
      this.showTemplateDialog = data
      this.showReportDialog = data
    },
    // 获取收藏夹列表
    /* async getFavorList () {
      const res = await getFavorList()
      if(res.data.length > 0){
        this.favorFolderList = res.data
      }
    }, */
    deleteTypeChange (val) {
      if (val.length > 1) {
        val.shift()
      }
    },
    batchDeleteChange (val) {
      if (val.length > 1) {
        val.shift()
      }
    },
    moveTypeChange (val) {
      if (val.length > 1) {
        val.shift()
      }
    },
    selectDeleteType (row) {
      this.showFavorFlag = true
      this.rowList = row
    },
    selectBatchDeleteType () {
      this.showBatchDeleteFlag = true
    },
    // 单条删除
    delectFavors (row) {
      this.$confirm('删除后将不可恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var isContain;
        if(this.form.isMerge === '0'){
          isContain = '0'
        }else{
          isContain = this.deleteFavorType[0]
        }
        this.loading = true
        const res = await removeFavorites({ sn: row.sn, sortId:row.nodeId, folderId:this.form.folderId,isContain:isContain,timeType:this.form.pubDate,startDate:this.form.date1,endDate:this.form.date2 })
        this.loading = false
        if(res.code === 0){
          this.$message.success('删除成功')
        }else{
          this.$message.error('删除失败')
        }
        this.showFavorFlag = false
        this.viewShowData()
      }).catch(() => {

      });

    },
    // 批量删除
    batchDelectFavors () {
      var snList = []
      if(this.multipleSelection.length > 0){
        snList = this.multipleSelection
        this.showBatchDeleteFlag = false
        this.$confirm('删除后将不可恢复, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.hideBatchPanel();
          var isContain;
          if(this.form.isMerge === '0'){
            isContain = '0'
          }else{
            isContain = this.batchDeleteType[0]
          }
          this.loading = true
          const res = await batchRemove({ removeList: snList, folderId:this.form.folderId,isContain:isContain,timeType:this.form.pubDate,startDate:this.form.date1,endDate:this.form.date2 })
          this.loading = false
          if(res.code === 0){
            this.$message.success('删除成功')
          }else{
            this.$message.error('删除失败')
          }
          this.viewShowData()
        }).catch(() => {

        });
      }else{
        this.$alert('请至少选择一条数据', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.hideBatchPanel();
          }
        })
      }
    },
    showMoveFlag (id,row) {
      this.newFolder = id
      this.favorNewsList = []
      if(row){
        this.favorNewsList.push(row)
        this.showFavorMoveFlag = true
      }else{
        if(this.multipleSelection.length > 0){
          this.favorNewsList = this.multipleSelection
          this.showFavorMoveFlag = true
        }else{
          this.$alert('请至少选择一条数据', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.hideBatchPanel();
            }
          })
        }
      }
    },
    moveFolder (id,row) {
      this.favorNewsList = []
      if(row){
        this.favorNewsList.push(row)
        this.moveFavorNews(id,this.favorNewsList)
      }else{
        if(this.multipleSelection.length > 0){
          this.favorNewsList = this.multipleSelection
          this.moveFavorNews(id,this.favorNewsList)
        }else{
          this.$alert('请至少选择一条数据', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.hideBatchPanel()
            }
          })
        }
      }
    },
    // 移动新闻
    async moveFavorNews (newFolderId,newslist) {
      var that = this
      var isContain;
      if(this.form.isMerge === '0'){
        isContain = '0'
      }else{
        isContain = this.moveFavorType[0]
      }
      this.showFavorMoveFlag = false
      setTimeout(()=>{
        that.hideBatchPanel()
      })
      const res = await updateFavorites({ listData: newslist,oldffId:this.form.folderId, newFfId: newFolderId, isMerge:this.form.isMerge,isContain:isContain,timeType:this.form.pubDate,startDate:this.form.date1,endDate:this.form.date2 })
      if(res.code === 0){
        this.$message.success('新闻移动成功')
      }else{
        this.$message.error('新闻移动失败')
      }
      this.viewShowData()
    },
    updateAbstract (row) {
      this.newSn = []
      this.showAlterSummaryFlag = true
      if (this.form.isMerge === '1') {
        this.newSn.push(row.groupSN)
      } else {
        this.newSn.push(row.sn)
      }
    },
    batchupdateAbstract () {
      this.newSn = []
      this.hideBatchPanel()
      if (this.multipleSelection.length == 0) {
        this.$alert('请至少选择一条数据', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.hideBatchPanel()
          }
        })
      } else {
        this.showAlterSummaryFlag = true
        this.multipleSelection.forEach((item, index) => {
          this.newSn.push(item.sn)
        })
      }
    },
    deleteSubNewsType (row) {
      this.showDeleSubNewsFlag = true
      this.rowList = row
    },
    selectSubNewsType (row) {
      this.showFlagSubNews = true
      this.rowList = row
    },
    // 显示批量操作面板
    showBatchPanel () {
      this.showBatchPanelFlag = true
    },
    // 隐藏批量操作面板
    hideBatchPanel () {
      this.showBatchPanelFlag = false
      this.hideEmotionPanel()
    },
    // 显示批量操作-情感选择的面板
    showEmotionPanel () {
      this.showEmotionPanelFlag = true
    },
    // 隐藏批量操作-情感选择的面板
    hideEmotionPanel () {
      this.showEmotionPanelFlag = false
    },
    // 显示操作菜单
    handleOver (row) {
      row.dropdownShow = true
    },
    // 隐藏操作菜单
    handleLeave (row) {
      row.dropdownShow = false
      this.hideMovePanel()
    },
    showMoveFolderPanel () {
      this.showMovePanel = true
    },
    hideMovePanel () {
      this.showMovePanel = false
    },
    // 批量标记
    batchSubNewsType () {
      if (this.form.isMerge === '1') {
        this.showBatchFlagSubNews = true
      } else {
        this.batchSetMark()
      }
    },
    // 批量移除
    batchDeleteSubNewsChange () {
      if (this.form.isMerge === '1') {
        this.showBtachDeleSubNewsFlag = true
      } else {
        this.cancelAllMark()
      }
    },
    favorTypeChange (val) {
      if (val.length > 1) {
        val.shift()
      }
    },
    falgSubNewsChange (val) {
      if (val.length > 1) {
        val.shift()
      }
    },
    async cancleDailyReport (row) {
      this.listSN.push(row.sn)
      try {
        const res = await cancelCreationOfDailyReportBySn({ listSN: this.listSN })
        if (res.code === 0) {
          this.$message.success('取消成功')
        } else {
          this.$message.error('取消失败')
        }
        this.viewShowData()
      } catch (e) {
        this.$message.error('取消失败')
        console.log(e)
      }
    },
    clearLike () {
      this.likeChild.currentPage = 1
      this.likeChildNewsTable = []
      // this.likeLoading = true
    },
    showLikeChildNews (val) {
      this.likeChild.likeSN = val
      this.listLikeChildNews()
      this.showLikeChildFlag = true
    },
    getNewsDetailsThroughSn (sn) {
      if (this.form.keyWord != ''){
      const { href } = this.$router.resolve({
        name: 'NewdetailBySnKeyword',
        params: {
          sn: sn,
          keyword: this.form.keyWord,
          detailkeyword: "keyword"
        }
      })
      window.open(href, '_blank')
      }else {
        const { href } = this.$router.resolve({
          name: 'NewdetailBySn',
          params: {
            sn: sn
          }
        })
        window.open(href, '_blank')
      }
    },
    async listLikeChildNews () {
      const res = await showLikeSonData({ likeSN: this.likeChild.likeSN, pageSize: this.likeChild.size, current: this.likeChild.currentPage })
      this.likeChildNewsTable = res.data.data
      this.likeChild.count = res.data.count
    },
    async updataMainNews (val) {
      this.showChildNewsFlag = false
      const res = await updataMainNews({ sn: val })
      this.viewShowData()
    },
    async viewsExportWord () {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
      } else {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        try {
          let token = getToken()
          const res = await exportWord({ snList: this.listSN, timeType: this.form.pubDate, startDate: this.form.date1, endDate: this.form.date2, isMerge: this.form.isMerge })
          console.log(res)
          if (res.code === 1) {
            let down = document.createElement('a')
            down.href = apiBaseURL + '/data/downloadWord?doc=' + res.msg + '&token=' + token
            down.click()
          }
          this.$message.success('下载成功')
        } catch (error) {
          this.$message.error('服务器内部异常')
        }
        this.listSN = []
      }
    },
    // 单独操作-取消添加至新闻组
    cancelOneNewsGroup () {
      this.showNewsGroupFlagOne = false
      this.$refs.addOneToNewsGroup_form.resetFields()
    },
    // 单独操作-添加至新闻组
    async saveOneNewsGroup () {
      var newsList = []
      newsList.push(this.addOneToNewsGroup_form.newsGroupId)
      newsList.push(this.oneAddToNewsGroupId)
      const res = await addNewsGroup({ sn: newsList })
      if (res.code === 0) {
        this.$message({
          message: '添加至新闻组成功',
          type: 'success'
        })
      } else {
        this.$message.error('添加至新闻组失败')
      }
      this.viewShowData()
      this.showNewsGroupFlagOne = false
      this.$refs.addOneToNewsGroup_form.resetFields()
    },
    handleSelectAllChange (val) {
      this.form.sourceType = val ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : []
    },
    handelTypeChange (val) {
      this.checkAll = val.length === 11
    },
    // 单独操作-展示解散新闻组提示弹窗
    disbandNewsGroup (row) {
      this.oneDisbandGroupId = row.groupSN
      this.showNewsGroupDisbandFlag = true
    },
    // 单独操作-展示添加至新闻组弹窗
    async oneAddToNewsGroup (row) {
      this.oneAddToNewsGroupId = row.sn
      const res = await listAllNewsGroup({ msId: this.$route.params.id, nodeIds: this.form.nodeids })
      this.groupList = res.data
      this.showNewsGroupFlagOne = true
    },
    // 单独操作-展示新闻组操作面板
    showOneNewsGroupPanel () {
      this.oneNewsGroupPanelFlag = true
      this.hideOneMarkPanel()
      this.hideOneEmotionPanel()
    },
    // 单独操作-隐藏新闻组操作面板
    hideOneNewsGroupPanel () {
      this.oneNewsGroupPanelFlag = false
    },
    cancelRemoveGroup () {
      this.showRemoveChildNews = false
    },
    async confRemoveGroup () {
      const res = await removeFavorites({ sn: this.childNewsSN.sn, sortId:this.childNewsSN.nodeId, folderId:this.form.folderId,isContain:'0',timeType:this.form.pubDate,startDate:this.form.date1,endDate:this.form.date2 })
      if (res.code === 0) {
        this.$message({
          message: '移除成功',
          type: 'success'
        })
      } else {
        this.$message.error('移除失败')
      }
      this.listChildNews()
      this.viewShowData()
      this.showRemoveChildNews = false
    },
    handleChildSizeChange (val) {
      this.child.size = val
      this.listChildNews()
    },
    // 当前页
    handleChildCurrentChange (val) {
      this.child.currentPage = val
      this.listChildNews()
    },
    handleLikeChildSizeChange (val) {
      this.likeChild.size = val
      this.listLikeChildNews()
    },
    // 当前页
    handleLikeChildCurrentChange (val) {
      this.likeChild.currentPage = val
      this.listLikeChildNews()
    },
    async addGroup () {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
        this.showConfAddGroup = false
      } else if (this.multipleSelection.length === 1) {
        this.$alert('单条新闻无法合并新闻组', '提示', {
          confirmButtonText: '确定'
        })
        this.showConfAddGroup = false
      } else {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          this.listSN.push(this.multipleSelection[i].sn)
        }
        const res = await addNewsGroup({ sn: this.listSN })
        if (res.code === 0) {
          this.$message({
            message: '合并新闻组成功',
            type: 'success'
          })
        } else {
          this.$message.error('合并新闻组失败')
        }
        this.viewShowData()
        this.showConfAddGroup = false
      }
    },
    async UpdateNew (row) {
      this.handleLeave(row)
      const res = await selectnNodeBySn({ sn: row.sn })
      console.log('580' + res)
      this.$router.push(`/monitor/edit/${row.sn}/${res.data.msId}/${res.data.nodeId}/1`)
    },
    async changeNode () {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
      } else {
        var selected = []
        for (var i = 0; i < this.multipleSelection.length; i++) {
          selected.push(this.multipleSelection[i].msMapId)
        }
        //  this.$route.params.id暂无
        const res = await setSubject({ snArray: selected, msId: -1, msNodeId: this.changeNodeId })
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.viewShowData()
          console.log(res)
        } else {
          this.$message.error(res.msg)
        }
        this.viewShowData()
      }
    },
    async listAllNode () {
      const res = await selectAllType()
      this.data = res.data
    },
    toNode (item) {
      this.changeNodeId = item.id
    },
    cancelConfAddGroup () {
      this.showConfAddGroup = false
    },
    alertSel () {
      this.$alert('请至少选择一条数据', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          this.hideBatchPanel()
        }
      })
    },
    async viewsRemoveAllMark () {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
      } else {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        var isFlagSubNews = parseInt(this.isFlagSubNews[0])
        if (this.form.isMerge === '0') {
          isFlagSubNews = 1
        }
        try {
          const res = await removeAllMark({ sns: this.listSN, isFlagSubNews: isFlagSubNews })
          console.log(res)
          if (res.code === 0) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.viewShowData()
            this.hideBatchPanel()
            console.log(res)
          } else {
            this.$message.error(res.msg)
          }
        } catch (err) {
          console.log(err)
        }
        this.showBtachDeleSubNewsFlag = false
      }
    },
    async viesSaveMarkRelationship () {
      for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.listSN.push(this.multipleSelection[i].sn)
      }
      var isFlagSubNews = parseInt(this.isFlagSubNews[0])
      if (this.form.isMerge === '0') {
        isFlagSubNews = 1
      }
      try {
        const res = await saveMarkRelationship({ snArray: this.listSN, markId: this.checkedMark, isFlagSubNews: isFlagSubNews })
        if (res.code === 200) {
          this.hideBatchPanel()
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.hideBatchPanel()
          this.viewShowData()
          console.log(res)
        } else {
          this.hideBatchPanel()
          this.$message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
      this.showBatchFlagSubNews = false
      this.checkedMark = []
    },
    async viewlistMarkGroupByCid () {
      try {
        const res = await listMarkGroupByCid()
        $.each(res.data, (index, item) => {
          item.checked = false
          // item.isIndeterminate = false
        })
        this.markgrou = res.data
        this.markgrouOne = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async viewPushNews () {
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        const res = await PushNews({ snlist: this.listSN })
        if (res.code === 200) {
          this.$message({
            message: '推送成功',
            type: 'success'
          })
        } else if (res.code === 400) {
          this.$message.error('请勿重复推送')
        } else {
          this.$message.error('推送失败')
        }
        this.viewShowData()
      } else {
        this.alertSel()
      }
    },
    async updateEmotion (emotion) {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
      } else {
        const res = await batchUpdateEmotion({ newsList: this.multipleSelection, type: emotion })
        if (res.code === 0) {
          this.$message({
            message: '情感状态设置成功',
            type: 'success'
          })
          this.hideBatchPanel()
        } else {
          this.hideBatchPanel()
          this.$message.error('情感状态设置失败')
        }
        this.viewShowData()
      }
    },
    async updateSummary (summary) {
      const res = await batchUpdateSummary({ isMerge: this.form.isMerge, newSn: this.newSn, summary: summary, timeType: this.form.pubDate, startDate: this.form.date1, endDate: this.form.date2 })
      if (res.code === 0) {
        this.$message({
          message: '摘要修改成功',
          type: 'success'
        })
      } else {
        this.$message.error('摘要修改失败')
      }
      this.viewShowData()
    },
    // 批量删除 删除新闻组且同时删除子新闻
    async deleteNews () {
      this.loading = true
      const res = await batchDeleteNews({ newsList: this.multipleSelection })
      this.loading = false
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.hideBatchPanel()
        this.viewShowData()
      } else {
        this.$message.error('删除失败')
      }
      this.showBatchDeleteFlag = false
    },
    // 批量删除 删除新闻组且同时解散新闻组
    async deleteCoreNews () {
      this.loading = true
      const res = await deleteAndNotIncludeSon({ newsList: this.multipleSelection })
      this.loading = false
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.hideBatchPanel()
        this.viewShowData()
      } else {
        this.$message.error('删除失败')
      }
      this.showBatchDeleteFlag = false
    },
    async viewsExportExcel () {
      if (this.multipleSelection.length === 0) {
        this.alertSel()
      } else {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        try {
          let token = getToken()
          let down = document.createElement('a')
          down.href = apiBaseURL + '/data/dataExcelExport?listSN=' + this.listSN + '&token=' + token + '&isMerge=' + this.form.isMerge + '&timeType=' + this.form.pubDate + '&startDate=' + this.form.date1 + '&endDate=' + this.form.date2
          down.click()
          this.$message.success('下载成功')
        } catch (error) {
          this.$message.error('服务器内部异常')
        }
      }
    },
    async listSiteType () {
      const res = await listAllSiteType()
      this.siteTypeList = res.data
    },
    // 查询所有已经处理的新闻
    async viewinformation () {
      // multipleSelection 这个是选中的数组
      console.log(this.multipleSelection)
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        console.log(this.listSN)
        if (this.multipleSelection.length === 1) {
          this.title = this.multipleSelection[0].title
        }
        try {
          console.log(this.listSN)
          const res = await addIntelligence({ snlist: this.listSN, title: this.title })
          if (res.code === 0) {
            this.$message({
              message: '设为情报成功',
              type: 'success'
            })
          } else if (res.code === 201) {
            this.$message.error('请勿重复设为情报')
          } else {
            this.$message.error('设为情报失败')
          }
          this.viewShowData()
          console.log(res)
        } catch (e) {
          console.log(e)
        }
      } else {
        // 弹窗提示请选择一条数据
        this.$alert('请选择一条数据', '提示', {
		  confirmButtonText: '确定'
        })
      }
    },
    async viewShowData () {
      localStorage.setItem("ffid",this.form.folderId)
      this.loading = true
      this.snlist = []
      this.listSN = []
      try {
        this.initSourceCount()
        const res = await getFavorListData({ pageSize: this.limit, currentPage: this.currentPage, markIdList:this.form.markIdList, timeType: this.form.pubDate, sourceType: this.form.sourceType, emotionType: this.form.emotionType, keyWord: this.form.keyWord, scope: this.form.scope, startDate: this.form.date1, endDate: this.form.date2, isMerge: this.form.isMerge, order: this.form.order, folderId: this.form.folderId, author: this.form.author, siteName: this.form.siteName })
        if (res.code === 0) {
          res.data.forEach((item, index) => {
            var i = 0
            if (item.isReport === 1) {
              i += 1
            }
            if (item.isIntelligence > 0) {
              i += 1
            }
            if (item.isPush > 0) {
              i += 1
            }
            if (i === 3) {
              item.classname = 'all'
            } else if (i === 2) {
              item.classname = 'tag2'
            } else if (i === 1) {
              item.classname = 'one'
            } else {
              item.classname = ''
            }
          })
          this.tableData = res.data
          this.count = parseInt(res.count)
          if (this.tableData.length > 0) {
            $('#div2').addClass("show-data")
          } else {
            $('#div2').removeClass("show-data")
          }
        } else if (res.code === 1) {
          this.tableData = []
          this.count = 0
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    getFormData (item) {
      this.form = Object.assign({}, item)
      if (this.form.pubDate === '5') {
        this.pubDateStyle.display = 'inline-block'
      } else {
        this.pubDateStyle.display = 'none'
      }
    },
    // 监听发布时间选中情况
    changeHandler () {
      if (this.form.pubDate === '5') {
        this.pubDateStyle.display = 'inline-block'
      } else {
        this.pubDateStyle.display = 'none'
        this.form.pubDateFrame = []
      }
    },
    // 格式化时间戳
    formatDate (time) {
	    if (typeof (time) === 'string' && time.indexOf('T') === -1) {
	      time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
	    }
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },
    // 筛选条件重置
    reset () {
      this.$refs['form'].resetFields()
        this.form.classifyRule = []
        this.form.date1 = ''
        this.form.date2 = ''
        this.form.delivery = false
        this.form.desc = ''
        this.form.emotionType = '-1'
        this.form.isMerge = '0'
        this.form.order = '2'
        this.form.keyWord = ''
        this.form.name = ''
        this.form.pubDate = '6'
        this.form.pubDateFrame = []
        this.form.region = ''
        this.form.scope = '1'
        this.form.sourceType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        this.form.tag = -1
        this.form.title = ''
      this.pubDateStyle.display = 'none'
      /* var tableId = document.getElementsByTagName('table')[1]
      for (var i = 0; i < tableId.rows.length; i++) {
        tableId.rows[i].cells[2].innerHTML = this.dataHtml[i]
      } */
    },
    selectChange () {
      this.$forceUpdate()
    },
    isMergeChange (val) {

      // if (val === '1') {
      //   this.form.order = '1'
      // } else {
      //   this.form.order = '1'
      // }
    },
    // 单独操作-导出数据
    oneExport (row, type) {
      this.exportNewsId = row.id
      this.exportType = type === 'Word' ? 'Word' : 'Excel'
      console.log(this.exportType)
      this.handleLeave(row)
    },
    // 单独操作-展示导出面板
    showExportPanel () {
      this.showOneExportPanelFlag = true
    },
    // 单独操作-隐藏导出面板
    hideExportPanel () {
      this.showOneExportPanelFlag = false
    },

    // 移除子新闻
    deleteChildNews (row) {
      this.childNewsSN = row
      this.showRemoveChildNews = true
    },
    // 显示子新闻列表
    showChildNews (val,selfSN) {
      this.child.groupSN = val
      this.child.parentSN = selfSN
      this.childNewsTable = []
      this.child.currentPage = 1
      this.showChildNewsFlag = true
      this.listChildNews()
    },
    async listChildNews () {
      this.groupLoading = true
      const res = await listSonData({ parentSN: this.child.parentSN, groupSN: this.child.groupSN, pageSize: this.child.size, currentPage: this.child.currentPage, timeType: this.form.pubDate, startDate: this.form.date1, endDate: this.form.date2,folderId:this.form.folderId,emotionType: this.form.emotionType, author: this.form.author, siteName: this.form.siteName  })
      console.log(res.data)
      this.childNewsTable = res.data.list
      console.log(this.childNewsTable)
      this.child.count = res.data.total
      this.groupLoading = false
    },

    // 收起筛选条件
    dropUp () {
      if(this.openFilter){
        this.openFilter = false
        this.filterStyle.width='100%'
      }else{
        this.openFilter = true
        this.filterStyle.width='55%'
      }
      // this.openFilter ? (this.openFilter = false) : (this.openFilter = true)
    },
    async initSourceCount () {
      const res = await sourceCountWithFolder({ msId: this.$route.params.id, nodeId: this.$route.params.nodeId,timeType: this.form.pubDate, sourceType: this.form.sourceType, emotionType: this.form.emotionType, isTag: 1, keyWord: this.form.keyWord, scope: this.form.scope, ruleId: this.form.nodeids, startDate: this.form.date1, endDate: this.form.date2, isMerge: this.form.isMerge, folderId: this.form.folderId, author: this.form.author, siteName: this.form.siteName })
      var sourceCountList = res.data
      this.totalNum = 0
      for (var i = 0; i < sourceCountList.length; i++) {
        this.totalNum += sourceCountList[i].count
        this.total = '全部(' + this.totalNum + ')'
        this.siteTypeList.forEach((item,index)=>{
          if(item.id === sourceCountList[i].sId){
            item.siteName = item.siteName.split('(')[0] + '(' + sourceCountList[i].count + ')'
          }
        })
        // this.siteTypeList[sourceCountList[i].sId - 1].siteName = this.siteTypeList[sourceCountList[i].sId - 1].siteName.split('(')[0] + '(' + sourceCountList[i].count + ')'
      }
    },
    // 提交表单
    onSubmit () {
      if (this.submitArr.length < 5) {
        this.submitArr.push(Object.assign({}, this.form))
      } else {
        /* var index = this.newindex % 5
        this.$set(this.submitArr, index, Object.assign({}, this.form))
        this.newindex += 1 */
        var item = this.submitArr.shift()
        this.submitArr.push(Object.assign({}, this.form))
      }
      localStorage.setItem(`reportConditions_${this.$store.state.user.name}`, JSON.stringify(this.submitArr))
      this.showCheckFlag = false
      this.$message.success('筛选条件保存成功')
      this.checkIndex = this.submitArr.length - 1
    },
    // 删除条件
    removeCondition (index) {
       this.$confirm('是否要删除该条件内容?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitArr.splice(index, 1)
          localStorage.setItem(`reportConditions_${this.$store.state.user.name}`, JSON.stringify(this.submitArr))
          this.showCheckFlag = false
          this.$message.success('条件删除成功')
         this.checkIndex = this.submitArr.length - 1 >= 0 ? this.submitArr.length - 1 : ''
        }).catch(() => {

        });

    },
    handleSizeChange (val) {
      this.backTop()
      this.limit = val
      this.viewShowData(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.backTop()
      this.currentPage = val
      this.viewShowData(val, this.size)
    },
    // 表格全选
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 根据条件查询，如果关键字存在，关键字标红
    search () {
      if (this.form.isMerge === '1') {
        this.isdisplay = true
        this.ismerge = false
      } else {
        this.isdisplay = false
        this.ismerge = true
      }
      this.currentPage = 1
      this.viewShowData()
    },

    async viewsAllExportExcel () {
      this.showExport= false
      if (this.count > 20000) {
        this.$message.warning('导出的新闻数量超过上限，此次导出仅保留20000条新闻组或普通新闻')
      } /* else {
        this.$message.success('正在生成文件')
      }
 */
      try {
        let token = getToken()
        var params = {fileName:this.fileName,timeType:this.form.pubDate,sourceType:this.form.sourceType,emotionType:this.form.emotionType,keyWord:this.form.keyWord,scope:this.form.scope,startDate:this.form.date1,endDate:this.form.date2,isMerge:this.form.isMerge,order:this.form.order,folderId:this.form.folderId,author:this.form.author,siteName:this.form.siteName,token:token}
        console.log(params)
        var res = await exportExcel(params)
        if(res.code == 0){
          this.$message.success(res.msg)
        }
      } catch (error) {
        this.$message.error('服务器内部异常')
      }
    },
    backTop () {
      document.documentElement.scrollTop -= 50;
      if (document.documentElement.scrollTop > 0) {
        var c = setTimeout(() => this.backTop(50), 16);
      } else {
        clearTimeout(c);
      }
    },
    async loadDiyTem () {
      const res = await listTemplates()
      this.sysTemArray = res.data
    },
    async createReportByTem (tId) {
      this.loading = true
      const res = await createWordByFf({
        temId: tId,
        vo: {
          pageSize: this.limit,
          currentPage: this.currentPage,
          markIdList: this.form.markIdList,
          timeType: this.form.pubDate,
          sourceType: this.form.sourceType,
          emotionType: this.form.emotionType,
          keyWord: this.form.keyWord,
          scope: this.form.scope,
          startDate: this.form.date1,
          endDate: this.form.date2,
          isMerge: this.form.isMerge,
          order: this.form.order,
          folderId: this.form.folderId,
          author: this.form.author,
          siteName: this.form.siteName
        }
      })
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  components: {
    dailyTemplate, dailyData
  },
  data () {
    return {
      sysTemArray: [],
      filterStyle:{
        height:'auto',
        width:'58%'
      },
      markgrouOne:[],
      showExport:false,
      showCheckFlag:false,
      checkIndex: '',
      fileName: '',
      showTemplateDialog: false,
      showReportDialog: false,
      isdisplay: false,
      ismerge: false,
      loading: false,
      groupLoading: false,
      showMovePanel: false,
      submitArr: [],
      favorFolderList: [],
      batchDeleteType: ['0'],
      deleteNewsType: [],
      moveFavorType: ['1'],
      deleteFavorType: ['0'],
      isFlagSubNews: ['0'],
      oneDeleteChild: 0,
      showFavorMoveFlag: false,
      showFavorFlag: false,
      showFlagSubNews: false,
      showBatchFlagSubNews: false,
      showDeleSubNewsFlag: false,
      showBtachDeleSubNewsFlag: false,
      newindex: 0,
      total: '全部',
      totalNum: 0,
      addOneToNewsGroup_form: { // 单独操作，添加至新闻组
        newsGroupId: '', // 存储新闻组主新闻id
        newsId: this.oneAddToNewsGroupId // 待添加至新闻组的新闻id
      },
      showLikeChildFlag: false,
      oneNewsGroupPanelFlag: false, // 是否展示单独操作的新闻组面板
      showNewsGroupFlagOne: false, // 是否展示单独操作的添加至新闻组弹窗
      groupList: [],
      oneAddToNewsGroupId: '',
      oneDisbandGroupId: '',
      showNewsGroupDisbandFlag: false,
      showRemoveChildNews: false,
      showConfAddGroup: false,
      changeNodeId: '',
      markgrou: [],
      cityOptions: [],
      siteTypeList: [],
      listSN: [],
      SNLists: [],
      dataHtml: [],
      checkAll: true,
      cities: cityOptions,
      isIndeterminate: false,
      checkedMark: [], // 批量操作-存储选中的标签id

      checkAllOne: false,
      isIndeterminateOne: false,
      checkedMarkOne: [], // 单独操作-存储选中的标签id

      oneDeleteId: [], // 待删除新闻的id
      exportNewsId: '', // 要导出的新闻id
      exportType: '', // 要导出的文件类型
      count: 0, // 共多少条数据
      limit: 10, // 一页多少条数据
      currentPage: 1, // 当前页
      multipleSelection: [], // 选中的表格数据
      viewType: 2, // 视图类型，1：标题   2：标题+摘要
      radio: '1',
      openFilter: true,
      newSn: [],
      showBatchPanelFlag: false, // 是否显示批量操作面板
      showEmotionPanelFlag: false, // 是否显示情感选择面板
      showMarkPanelFlag: false, // 是否显示标签组面板
      showBatchDeleteFlag: false, // 是否批量删除
      showAlterSummaryFlag: false, // 是否显示修改摘要弹框
      showAddMarkFlag: false, // 是否显示新建标签弹框
      showChildNewsFlag: false, // 是否显示子新闻列表
      showOneEmotionPanelFlag: false, // 是否展示单独操作的情感选择面板
      showOneMarkPanelFlag: false, // 是否展示单独操作的标签组选择面板
      showOneExportPanelFlag: false, // 是否展示单独操作的导出面板
      showOneDeleteFlag: false, // 是否单独删除新闻

      alterSummary_form: { // 批量修改摘要的表单
        // data: this.multipleSelection, // 获取选中的数据（一行）
        summary: ''
      },
      pubDateStyle: {
        display: 'none',
        margin: '0 15px'
      },
      addMark_form: { // 新建标签的表单
        name: '',
        region: ''
      },
      options: [],
      child: {
        count: 0,
        size: 10,
        currentPage: 1,
        groupSN: '',
        parentSN: '',
      },
      childNewsSN: '',
      form: {
        title: '',
        name: '',
        region: '',
        date1: '',
        date2: '',
        folderId: '',
        delivery: false,
        sourceType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        pubDate: '1',
        pubDateFrame: [], // 时间区间
        emotionType: '-1',
        tag: '-1',
        scope: '1',
        desc: '',
        keyWord: '',
        classifyRule: [],
        nodeids: [],
        isMerge: '0',
        order: '2',
        author: '',
        siteName: ''
      },
      showItems:false,
      tableData: [],
      childNewsTable: [],
      likeChildNewsTable: [],
      likeChild: {
        count: 0,
        size: 10,
        currentPage: 1,
        likeSN: ''
      },
      props: {
        label: 'name',
        children: 'zones'
      },
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    'favorFolderList': {
      handler (val) {
        if(val.length === 0){
          this.favorFolderList = JSON.parse(sessionStorage.getItem('favorList'))
        }
      },
      deep: true,
      immediate: true
    },
    'form.folderId': {
      handler (val) {
        if(val!=="") {
          this.viewShowData()
        }
      },
      deep: true,
      immediate: true
    },
    'form.pubDateFrame': {
      handler (val) {
        if (val.length > 0) {
          this.form.date1 = this.formatDate(val[0])
          this.form.date2 = this.formatDate(val[1])
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
  .iconList{
    float: right;
    .iconfont{
      margin: 0 5px;
      cursor: pointer;
    }
  }
  .el-select__tags{
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .report-wrap {
    // width: 100%;
    height: 100%;
    margin-left: 20px;
    // padding: 30px 30px;
    // display: flex;
    .footer{
      span:not(.el-tag){
        margin-right: 10px;
        // font-style: oblique;
        color: #b2babb;
        .tags{
          font-style: normal;
          color: #3472c7;
          margin-right: 8px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    .report-content {
      width: 100%;
      // margin-left: 200px;
      /*.footer{
        font-style: oblique;
        color: #b2babb;
        span{
          margin-right: 10px;
        }
        .el-tag--dark {
            color: white;
            font-style: normal;
        }
      } */
      .operator-wrapper{
        float: right;
        display: inline-block;
        .isOriginal{
          margin-right: 10px;
        }
      }
      .card-content{
        margin: 5px 0;
        line-height: 1.5;
        .el-tag{
          margin-right: 5px;
        }
      }
      #div1,
      #div2 {
        width: 100%;
        border-radius: 3px;
        // margin-left: 30px;
        margin-bottom: 30px;
        border: 1px #eceaea solid;
        background: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
      }
      #div1 {
        .btn-wrapper{
          .el-input--mini .el-input__inner{
            height: 31px;
            line-height: 31px;
          }
        }
        padding: 20px 25px;
        position: relative;
        &>div:last-child{
          margin: 22px 0 10px;
        }
        &>div.sourceType>div{
          display: inline-block;
          vertical-align: text-top;
          &.allData{
            width: 100px;
            margin-right: 97px;
          }
          &.mediumSource{
            width: 270px;
            margin-right: 97px;
          }
          &.community{
            width: 270px;
            margin-right: 97px;
          }
          &.micro{
            width: 100px;
            //margin-left: 97px;
          }
          label.title{
            color: #565656;
            font-size: 15px;
            display: block;
          }
        }
        &>div:not(:last-child):not(.sourceType){
          border-bottom: 1px solid #efefef;
          .items{
            padding: 5px 0;
            display: inline-block;
            position: relative;
            &:first-child{
              width: 60%;
            }
            &:last-child{
              width: 40%;
            }
            .el-input,.el-select{
              width: 230px;
              margin-right: 20px;
            }
            .el-date-editor .el-range__icon {
                line-height: 32px;
            }
          }
          .title{
            // font-weight: 400;
            color: #565656;
            font-size: 15px;
            // display: inline-block;

            .el-radio--mini.is-bordered:not(.is-disabled) .el-radio__label,
            .el-checkbox__input.is-checked + .el-checkbox__label{
              color: #838383;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner{
              background-color: #3472c7;
              border-color: #3472c7;
            }

          }
        }
        .el-dropdown-link{
          position: absolute;
          right: 1px;
          bottom: -1px;
          img{
            width: 30px;
            height: 30px;
          }
        }
        .el-cascader {
          .el-input__inner{
            height: 30px !important;
          }
        }
        .el-input{
          width: 98%;
          .el-input__inner{
            height: 30px !important;
          }
        }
        .dateContainer {
          color: #000000;
          font-size: 15px;
        }
        .beginTime,
        .endTime {
          font-size: 12px;
        }
        .el-date-editor {
          position: absolute;
          left: 30%;
          bottom: -33px;
          z-index: 1;
        }
        .el-date-editor.el-input__inner{
          height: 35px !important;
        }
        .el-form-item .el-input__icon{
          padding: 0;
          margin: 0 -5px;
        }
        .el-checkbox {
              margin-right: 15px;
            }

            .el-checkbox-group {
              margin-bottom: -3px;
            }

          .el-radio-group {
            margin-bottom: 3px;

            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              margin-left: 20px;
            }

            .el-date-editor--daterange {
              height: 30px !important;
            }
          }

          .el-radio.is-bordered.is-checked:not(.is-disabled){
            border: 1px solid #3472c7;
          }
          .el-radio__input.is-checked:not(.is-disabled) + .el-radio__label{
            color: #3472c7 !important;
          }
          .el-radio--mini.is-bordered {
            padding: 5px 10px 0px 0px;
            margin-right: 1px;
          }

          #emotion {
            padding: 3px 1px 1px 0px;
          }

          #emotion .el-checkbox.is-bordered.el-checkbox--mini {
            padding: 4px 10px 0px 0px;
          }

          #emotion .el-checkbox__input {
            display: none;
          }

          .el-button {
            height: 30px;
            padding-top: 8px;
          }

          #searchBtn {
            margin-left: 12px;
          }

          .el-date-editor .el-range-separator {
            padding-bottom: 11px;
          }

        }

        .el-radio__input {
          display: none;
        }

        .el-dropdown-link {
          cursor: pointer;
          color: #3472c7;
        }
        .sourceType{
          border-bottom: 1px solid #efefef;
          padding: 5px 0;
          font-size: 15px;
          // text-align: center;
          /deep/ .el-checkbox__label{
            color: #838383;
            padding: 5px;
            font-weight: 500;
          }
          .el-checkbox{
            // padding-left: 10px;
            margin-top: 5px;
            margin-right: 5px;
           /* width: 110px;
            border-right: 1px solid #DCDFE6; */
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color:#727171;
          }
          .el-checkbox__label{
           // border-radius: 3px;
           padding: 5px;
          }
          .el-checkbox-group {
             display: inline;

             .el-checkbox:last-child {
              margin-right: 0;
             }
             /* .el-checkbox__input{
               display: none;
             } */

             .el-checkbox:last-child .el-checkbox__label{
              border-right: none;
             }
             /* .el-checkbox__input.is-checked + .el-checkbox__label {
                border: 1px solid #409EFF;

             } */
          }
        }
      }
      #div2 {
        position: relative;
      }

      #div3 {
        padding: 30px 30px 30px 30px;

        .batchBtn {
          position: relative;
        }

        #div4 {
          display: inline-block;
          float: right;
        }

        .el-dropdown {
          margin-right: 10px;
        }

        #div5 {
          border: 1px #eceaea solid;
        }
        .batchMenu {
          width: 100px;
          // height: 142px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 27.5px;
          left: -10px;
          z-index: abs($number: 999);

          .menuUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
              color: rgb(14, 13, 13);
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .emotionMenu {
          width: 100px;
          // height: 100px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 0px;
          left: 101px;
          z-index: abs($number: 999);

          .emotionUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: center;
              padding-left: 0px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .openLabel {
          position: relative;

          .mark-wrap {
            width: 300px;
            height: 330px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
            position: absolute;
            top: -17px;
            left: 101%;
            padding: 15px;

            .table-wrap {
              height: 200px;
              border: 1px solid #979797;
              overflow-x: auto;
              overflow-y: auto;

              .mark-table {
                display: flex;

                .table-col {
                  padding: 0;
                  border-right: 1px solid #979797;
                  flex-grow: 1;
                  flex-shrink: 0;

                  &:last-child {
                    border-right: none;
                  }

                  .mark-header {
                    border-bottom: 1px solid #979797;
                  }

                  li {
                    text-align: left;
                    padding: 0 10px;
                    // width: 100px;
                  }
                }
              }
            }

            .table-bottom {
              padding-top: 5px;
            }
          }
        }
      }

      .el-table {
        width: calc(100% - 40px);
        margin-bottom: 40px;
        border-radius: 10px;
        margin-left: 20px;
        overflow: visible;


        th.gutter {
          display: table-cell !important;
        }

        &.el-table th>.cell {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .caozuo {
          width: 25px;
          height: 25px;
        }

        .oneOperationPanel {
          width: 115px;
          // height: 180px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 30px;
          right: 20px;
          z-index: abs($number: 999);

          .oneOperationUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
              color: rgb(14, 13, 13);
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .showOneEmotionPanel {
          width: 100px;
          // height: 103px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          top: 45%;
          right: 45px;
          z-index: abs($number: 999);

          .oneEmotionUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: center;
              padding-left: 0px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .oneMarkPanel {
          position: relative;

          .mark-wrap-one {
            width: 300px;
            height: 330px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
            position: absolute;
            top: -17px;
            right: 116px;
            padding: 15px;
            border: 1px #e6e0e0 solid;

            .table-wrap-one {
              height: 200px;
              border: 1px solid #979797;
              overflow-x: auto;
              overflow-y: auto;

              .mark-table-one {
                display: flex;

                .table-col {
                  padding: 0;
                  border-right: 1px solid #979797;
                  flex-grow: 1;
                  flex-shrink: 0;

                  &:last-child {
                    border-right: none;
                  }

                  .mark-header {
                    border-bottom: 1px solid #979797;
                  }

                  li {
                    text-align: left;
                    padding: 0 10px;
                    // width: 100px;
                  }
                }
              }
            }

            .table-bottom-one {
              padding-top: 5px;
            }
          }
        }

        .newsGroupPanelOne {
          width: 110px;
          height: 68px;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          // top: 207.5px;
          top: 175px;
          right: 116px;
          z-index: abs($number: 999);

          .oneNewsGroupUl {
            padding-left: 0px;
            line-height: 2.5;
            font-size: 14px;

            li {
              text-align: left;
              padding-left: 15px;
            }

            li:hover {
              background-color: rgb(203, 206, 209);
              cursor: pointer;
            }
          }
        }

        .bottomEllipsis {
          .oneOperationPanel {
            // bottom: 32px;
          }
        }
      }



      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
      }

    .dialog_form {
      text-align: left;
    }
  }

  .prompt {
    height: 28px;
    display: flex;
    align-items: center;

    .el-icon-warning {
      font-size: 28px;
      color: #FF8B35;
      margin-right: 12px;
    }
  }

  .el-tree-node__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-table{
    .title{
      width: 75%;
      color: #3d3d3d;
      font-size: 16px;
      color: #3472c7;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }
  }
  #div5 {
    .childCount{
      // color: #409EFF;
      cursor: pointer;
    }
    .showAbstract{
      width: 90%;
      color: #409EFF;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      cursor: pointer;
    }
    .abstract {
      display: -webkit-box;
      // 超过两行省略号显示
      line-clamp:2;
      -webkit-line-clamp:2;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      position: relative;
    }
  }
</style>
