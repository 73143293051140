import request from '@/utils/request'

// 查询收藏夹列表
export function getFavorList (data) {
  return request({
    url: '/favorite_folder/list',
    method: 'POST',
    data
  })
}
// 新建收藏夹
export function addFavor (data) {
  return request({
    url: '/favorite_folder/add',
    method: 'POST',
    data
  })
}
// 删除收藏夹
export function deleteFavor (data) {
  return request({
    url: '/favorite_folder/delete',
    method: 'POST',
    data
  })
}
// 获取收藏夹数据列表
export function getFavorListData (data) {
  return request({
    url: '/favorite_folder/listData',
    method: 'POST',
    data
  })
}
// 添加数据到收藏夹
export function addFavorNews (data) {
  return request({
    url: '/favorite_folder/addNews',
    method: 'POST',
    data
  })
}
// 从收藏夹中移除数据
export function removeFavorites (data) {
  return request({
    url: '/favorite_folder/removeFavorites',
    method: 'POST',
    data
  })
}
// 批量移除数据
export function batchRemove (data) {
  return request({
    url: '/favorite_folder/batchRemoveData',
    method: 'POST',
    data
  })
}
// 移动数据
export function updateFavorites (data) {
  return request({
    url: '/favorite_folder/updateDataFavorites',
    method: 'POST',
    data
  })
}
// 导出
export function exportExcel (data) {
  return request({
    url: '/favorite_folder/exportExcel',
    method: 'POST',
    data:data
  })
}
